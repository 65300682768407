import React from 'react'
import Layout from '../components/layout'
import Head from '../components/head'
import Img from "gatsby-image"
import { graphql } from 'gatsby'

import indexStyles from './index.module.scss'

const indexPage = (props) => {
  return (
      <Layout>
        <Head title="Home" />
        <div className={indexStyles.container}>
        <h1>An English Speaking Catholic Parish in Monterrey, Mexico</h1>
        <Img fluid={props.data.imageOne.childImageSharp.fluid} alt="Immaculate Mary Chapel in Monterrey, Mexico" className={indexStyles.hero} />
        <h2>Welcome to Immaculate Mary Parish!</h2>
        <p>We are an English speaking Catholic church in Monterrey, Mexico. Our community comes from all parts of the world and we are united by our faith.</p>
        <p>Come join us for English mass in Monterrey, fellowship, and more.</p>
        <h3>English Mass Schedule</h3>
        <p>Sundays at 10:30 AM in Fatima's Chapel. Mass ends at 11:15.</p>
        <p>During the COVID pandemic, we continue to have socially-distanced mass unless the Archdiocese closes services on Sundays.</p>
        <div className={indexStyles.columns}>
          <div className={indexStyles.columnOne}>
            <h3>Location</h3>
            <p>We are located at Nuestra Señora de Fátima Sanctuary</p>
            <h4>Address:</h4>
            <address>
            Rio Tiber S/N Col. Del Valle<br/>
            San Pedro Garza García<br/>
            Nuevo León, México<br/>
            C.P. 66220<br/>
            </address>
            <p>Note: Chapel is located at the back of the church.</p>
          </div>
          <div className={indexStyles.columnTwo}>
          <a href="https://www.google.com/maps/place/Parish+and+Shrine+of+Our+Lady+of+Fatima/@25.645196,-100.3759473,16.5z/data=!4m5!3m4!1s0x866297c2a9555555:0x1926888214c98f1d!8m2!3d25.6493618!4d-100.3716409" target="_blank" >
            <Img  className={indexStyles.map} fluid={props.data.fatimaMap.childFile.childImageSharp.fluid} alt="Map to Immaculate Mary Chapel" />
          </a>
          </div>
        </div>
        <div className={indexStyles.form}>
            <p>Subscribe to our emails to get the latest updates!</p>
            <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="emailList">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="emailList" />
                <label className={indexStyles.formLabels}>
                    <div>Full Name</div>
                    <input className={indexStyles.formInput} type="text" name="name" id="name" />
                </label>
                <label className={indexStyles.formLabels}>
                    <div>Email</div>
                    <input className={indexStyles.formInput} type="email" name="email" id="email" />
                </label>
                <button className={indexStyles.formSubmit} type="submit">Subscribe</button>
            </form>
            </div>
        </div>
      </Layout>
  )  
}

export default indexPage

export const pageQuery =  graphql`
query {
  imageOne: file(relativePath: { eq: "images/chapel.png" }) {
    childImageSharp {
        fluid (quality: 90){
          ...GatsbyImageSharpFluid
          }  
    }
  }
  fatimaMap:
  staticMap {
      mapUrl
      childFile {
          childImageSharp {
              fluid (quality: 90) {
                  ...GatsbyImageSharpFluid
              }
          }
      }
  }
  }`;
